import { firestore } from "../firebase";
import { doc, setDoc, deleteDoc } from "firebase/firestore";

const f_publicacoes = {};

f_publicacoes.update = async (doc_id, pub_data) => {
    const docRef = doc(firestore, "publicacoes", doc_id);
    await setDoc(docRef, pub_data, { merge: true });
}

f_publicacoes.delete = (doc_id) => {
    const docRef = doc(firestore, "publicacoes", doc_id);
    deleteDoc(docRef);
}

export default f_publicacoes;