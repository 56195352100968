import { firestore } from "../firebase";
import { collection, onSnapshot, doc, query } from "firebase/firestore";

export const streamDoc = (collection, doc_id, snapshot, error) => {
    let collRef = doc(firestore, collection, doc_id)
    return onSnapshot(collRef, snapshot, error);
};

export const streamCollection = (collection_name, where, snapshot, error) => {
    let collRef = collection(firestore, collection_name)
    if (where !== undefined) {
        for (let where_filter of where) {
            collRef = query(collRef, where_filter);
        }
    }
    return onSnapshot(collRef, snapshot, error);
};

export const streamSubCollection = (main_collection, main_doc_ref, sub_collection, where, snapshot, error) => {
    const mainDoc = doc(firestore, main_collection, main_doc_ref);
    let subCol = collection(mainDoc, sub_collection)
    if (where !== undefined) {
        subCol = query(subCol, where);
    }
    return onSnapshot(subCol, snapshot, error);
};
