import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import AddTask from '@mui/icons-material/AddTask';
import DeleteForever from '@mui/icons-material/DeleteForever';
import {
    Paper,
    Dialog,
    Typography,
    AppBar,
    Toolbar,
    IconButton,
    DialogContent,
    DialogTitle,
    Grid,
    Button,
    Fab,
    Tooltip
} from "@mui/material";
import PropTypes from "prop-types";

import f_publicacoes from "../../services/f_publicacoes";
import ConfirmationDialog from "../ConfirmationDialog";

const ComparePaper = styled(Paper)(({ theme }) => ({
    width: '100%',
    height: '50vmin',
    textAlign: 'center',
    overflow: 'auto'
}));

const InfosPaper = styled(Paper)(({ theme }) => ({
    padding: '1vmin',
    textAlign: 'center'
}));

function ViewPublicacaoDialog(props) {
    const [dialogDeletePubOpen, setdialogDeletePubOpen] = useState(false);

    function highlightText(string1, string2) {
        let words = string1.split(" ");

        let output = [];
        words.forEach((word) => {
            if (string2.includes(word)) {
                output.push(<span style={{ backgroundColor: 'yellow' }}>{word + " "}</span>);
            } else {
                output.push(<span>{word + " "}</span>);
            }
        });

        return output;
    }

    const handleClose = () => {
        props.handleClose(false, undefined);
    };

    const handleClickEnviarProcessamento = () => {
        f_publicacoes.update(props.publicacaoData.doc_id, {
            status: "aguardando_processamento"
        });
        props.handleClose(false, undefined);
        props.openSnackbar(`Publicação enviada para processamento.`);
    };

    const handleClickDeletePub = () => {
        f_publicacoes.update(props.publicacaoData.doc_id, {
            status: "ignorada"
        });
        //f_publicacoes.delete(props.publicacaoData.doc_id);
        props.handleClose(false, undefined);
        props.openSnackbar(`Publicação ignorada.`);
    };

    if (props.publicacaoData === undefined || props.publicacaoData === null) {
        return (<React.Fragment></React.Fragment>)
    }
    
    return (
        <React.Fragment>
            <ConfirmationDialog
                dialogProps={{
                    open: dialogDeletePubOpen,
                    onClose: () => setdialogDeletePubOpen(false),
                }}
                content={<Typography variant="subtitle1" component="div" sx={{ textTransform: 'uppercase', m: 1 }}>Deseja ignorar a publicação do processo: {props.publicacaoData?.num_processo} ?</Typography>}
                dismissiveAction={<Button color="primary" onClick={() => setdialogDeletePubOpen(false)}>Cancelar</Button>}
                confirmingAction={<Button color="warning" onClick={() => handleClickDeletePub(true)}>Sim</Button>}
            />
        
            <Dialog fullWidth maxWidth="lg" open={props.open}>
                <DialogTitle sx={{ m: 1, p: 0 }}>
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>

                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                NUM. PROCESSO (PUBLICAÇÃO): {props.publicacaoData.num_processo || "N/A"}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </DialogTitle>

                <DialogContent sx={{ m: 1, p: 1 }}>
                    <Grid container justifyContent="center" alignItems="center" spacing={2}>
                        <Grid item xs={12}>
                            <InfosPaper>
                                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography variant="subtitle1">
                                            Acertividade: {props.publicacaoData.score || "N/A"}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="subtitle1">
                                            Portal: {props.publicacaoData.portal || "N/A"}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="subtitle1">
                                            Recurso: {props.publicacaoData.nome_recurso || "N/A"}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="subtitle1">
                                            Data Disponibilização: {props.publicacaoData.data_disponibilizacao.toDate().toLocaleDateString('pt-BR') || "N/A"}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="subtitle1">
                                            Data Publicação: {props.publicacaoData.data_publicacao.toDate().toLocaleDateString('pt-BR') || "N/A"}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="subtitle1">
                                            Usuário: {props.publicacaoData.usuario_portal || "N/A"}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="subtitle1">
                                            Status: {props.publicacaoData.status === "aguardando_processamento" ? "Publicação analisada e aguardando processamento." : props.publicacaoData.status === "pendente" ? "Publicação aguardando análise." : props.publicacaoData.status === "ignorada" ? "Publicação ignorada." : "Publicação processada."}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </InfosPaper>
                        </Grid>

                        <Grid item xs={12}>
                            {/* infos da publicação / comparação infos */}
                            <Grid container justifyContent="center" alignItems="center" spacing={2}>
                                <Grid item xs={6}>
                                    <ComparePaper variant="outlined">
                                        <Typography variant="subtitle2" gutterBottom>
                                            {highlightText(props.publicacaoData.texto_publicacao, props.publicacaoData.texto_comparativo)}
                                        </Typography>
                                    </ComparePaper>
                                </Grid>
                                <Grid item xs={6}>
                                    <ComparePaper variant="outlined">
                                        <Typography variant="subtitle2" gutterBottom>
                                            {highlightText(props.publicacaoData.texto_comparativo, props.publicacaoData.texto_publicacao)}
                                        </Typography>
                                    </ComparePaper>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <InfosPaper>
                                <Grid container justifyContent="center" alignItems="center" spacing={8}>
                                    {(props.publicacaoData.status === "pendente" || props.publicacaoData.status === "ignorada") && 
                                        <Grid item>
                                            <Tooltip title="Enviar publicação para processamento" arrow>
                                                <Fab size="small" color="secondary" aria-label="enviar para processamento" >
                                                    <AddTask onClick={() => handleClickEnviarProcessamento()} />
                                                </Fab>
                                            </Tooltip>
                                        </Grid>
                                    }
                                    <Grid item>
                                        <Tooltip title="Ignorar publicação" arrow>
                                            <Fab size="small" color="warning" aria-label="ignorar publicação" >
                                                <DeleteForever onClick={() => setdialogDeletePubOpen(true)} />
                                            </Fab>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </InfosPaper>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}

ViewPublicacaoDialog.propTypes = {
    openSnackbar: PropTypes.func.isRequired,
    publicacaoData: PropTypes.object
};

export default ViewPublicacaoDialog;