import React, { Component } from "react";

import PropTypes from "prop-types";

import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";

import NotFoundPage from "../NotFoundPage";
import LoginPage from "../LoginPage";
import PublicacoesPage from "../PublicacoesPage";

class Router extends Component {
	render() {
		// Properties
		const { user, roles, bar } = this.props;

		// Functions
		const { openSnackbar } = this.props;

		return (
			<BrowserRouter basename={process.env.REACT_APP_BASENAME}>
				{user && bar}

				<Switch>
					<Route path="/login" exact>
						{!user ? (
							<LoginPage user={user} openSnackbar={openSnackbar} />
						) : (
							<Redirect to="/" />
						)}
					</Route>

					<Route path="/" exact>
						{user ? (
							<PublicacoesPage user={user} roles={roles} openSnackbar={openSnackbar} />
						) : (
							<Redirect to="/login" />
						)}
					</Route>
					<Route>
						<NotFoundPage />
					</Route>
				</Switch>
			</BrowserRouter>
		);
	}
}

Router.propTypes = {
	// Properties
	user: PropTypes.object,
	roles: PropTypes.array.isRequired,
	bar: PropTypes.element,

	// Functions
	openSnackbar: PropTypes.func.isRequired,
};

export default Router;
